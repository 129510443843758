/**
 * PIMC (Program Interruption Management Counter) - PROFADS-16139
 * Tracks floating ads exposure across legacy/Aspen pages.
 * Shared between profads2.js and medscapeads.js.
 * Products: BrandAlert SGV, MAN Layer, Promo/Funded Driver Layer,
 * NativeCONNECT Layer, Aggressive Drivers.
 */

/**
 * Sets Program Interruption Counter with 30-min expiry.
 * Resets on PPID expiry/missing cookie
 * @param {number} value - Initial counter value
 * @param {boolean} ppidExpired - PPID expiry status
 */
export function setPIMC(value = 0, ppidExpired = false) {
  const pimcCookie = (document.cookie.match(/(?:^|;\s*)PIMC=([^;]*)/) || [])[1] || null;
  if (pimcCookie == null || ppidExpired) {
    webmd.ads2.setPageTarget('pimc', 0);
    const expiryDate = new Date(Date.now() + 30 * 60 * 1000);
    document.cookie = `PIMC=${value}; expires=${expiryDate.toUTCString()}; domain=.medscape.com; path=/; secure`;
    document.cookie = `EXPIRY_TIME=${expiryDate.toUTCString()}; expires=${expiryDate.toUTCString()}; domain=.medscape.com; path=/; secure`;
  } else {
    webmd.ads2.setPageTarget('pimc', pimcCookie);
  }
}

/**
 * Increments counter when user is served floating/interruptive ads.
 * Resets: on expiry or PPID refresh.
 */
export function updatePIMC() {
  const pimcCookie = (document.cookie.match(/(?:^|;\s*)PIMC=([^;]*)/) || [])[1] || null;
  if (pimcCookie == null) {
    profads.ads2.setPIMC(1);
    return;
  }
  const currentValueInt = !isNaN(pimcCookie) ? parseInt(pimcCookie, 10) : 0;
  const newPimcValue = currentValueInt + 1;
  const expiryCookie = (document.cookie.match(/(?:^|;\s*)EXPIRY_TIME=([^;]*)/) || [])[1] || null;
  if (expiryCookie) {
    document.cookie = `PIMC=${newPimcValue}; expires=${expiryCookie}; domain=.medscape.com; path=/; secure`;
  } else {
    profads.ads2.setPIMC(1);
  }
}
