import { debug } from "../common/debug-log";

function postRecricWidgetSetUp(data) {
  let _adContainer = document.getElementById(data.slotId);
  if (!_adContainer) {
    debug.error(`[profads] element with id ${data.slotId} not found`);
    return;
  }
  let _innerAdDiv = _adContainer.querySelector("div");
  let topPick = document.getElementById("top-pick");
  _adContainer.classList.remove("inactive");
  _adContainer.classList.add("recircwidget");
  _adContainer.style.width = "100%";
  // is Fast Five Quiz?, if true add padding
  let ffq = document.querySelector(".fast-five-quiz");
  if (ffq) {
    let _widthObj = { width: "90%", "max-width": "750px" };
    let _topPickCSSObj = { ..._widthObj };
    _topPickCSSObj["margin-left"] = "auto";
    _topPickCSSObj["margin-right"] = "auto";
    topPick.style = _topPickCSSObj;
    _innerAdDiv.style = _widthObj;
  }

  let recircWidget = document.querySelectorAll(".recircwidget");
  //to remove class from first and last recirc widgets
  recircWidget.forEach((widget, index) => {
    if (index != 0) {
      widget.classList.remove("recircborder");
    } else {
      widget.classList.add("recircborder");
    }
    if (index != recircWidget.length - 1) {
      widget.classList.remove("recircmarginbottom");
    } else {
      widget.classList.add("recircmarginbottom");
    }
  });
  if (topPick) {
    topPick.style.display = "block";
  }
}

export default postRecricWidgetSetUp;

