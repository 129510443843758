export default {
  name: 'doubleVerify',
  init() {
    const dv = 'https://pub.doubleverify.com/signals/pub.js#ctx=17778638&cmp=DV587414&signals=ids,bsc,vlp,tvp,abs,qt_loaded';
    profads.ads2.utils.loadScript({ src: dv, handler: this.onScriptLoad, async: true });
    webmd.tpsvc.promises.push(this.holdAdscallPromise);
  },
  onScriptLoad() {
    //empty callback for future use
  },
  holdAdscallPromise() {
    return new Promise((resolve, reject) => {
      var checkInterval = setInterval(() => {
        if (typeof PQ === 'object' && PQ !== null) {
          clearInterval(checkInterval);
          clearInterval(dvTimeout);
          //debug.log("DV excuted on Time");
          let fn = () => {};
          PQ.cmd.push(() => {
            console.log("DV load Signals Loaded excuted on Time");
            PQ.loadSignals([ 'ids', 'bsc', 'vlp', 'tvp', 'abs' ], fn);
          });
          resolve('PQ is an object');
        }
      }, 100);

      var dvTimeout = setTimeout(()=>{
        console.log("DV time out");
        clearInterval(checkInterval);
        reject('PQ is a Rejected');
      }, 750)

    })
  }
};

