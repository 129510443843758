const formulary = {
  /* Helper function to get journey ID */
  getJourneyId(actId) {
    const actDtls = (window.PageMetadata && window.PageMetadata.extraObject && window.PageMetadata.extraObject.actDtls)
      ? window.PageMetadata.extraObject.actDtls : null;

    if (!Array.isArray(actDtls) || !actDtls.length) return null;
    const journeyIdArr = actDtls.filter((item) => (item.ct && item.ct.toUpperCase() == 'FAP') && item.a == actId);
    return (journeyIdArr[0] && journeyIdArr[0].j) ? journeyIdArr[0].j : null;
  },

  /* Helper function to load formulary creative code file */
  displayFormularyAccess(data) {
    const that = this;
    const methodName = 'formularyAccessfn';
    const fileName = 'formulary-access.js';

    window.profads.ads2.utils.loadScript({
      src: `https://img${window.profads.ads2.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/js/dfp/external/${fileName}`,
      handler() {
        if (typeof that[methodName] !== 'function') return;
        that[methodName](data);
      },
      errorHandler(err) { console.error('[profads] Formulary Access ad script failed to load.', err) },
      async: true
    });
  },

  /* Main formulary access API function */
  formularyAccessApi(data) {
    const channelId = 2;
    let journeyId;
    const urlParams = new URLSearchParams(window.location.search);
    const journeyIdFromUrl =  urlParams.get('journeyId');  // For internal use (PROFADS-17159)
    journeyId = journeyIdFromUrl  ? journeyIdFromUrl : formulary.getJourneyId(data.actId);

    const payLoad = data;

    let matchCookieValue = document.cookie.match(/med_session=([^;]+)(?:;|$)/);
    let medSessionCookieValue = matchCookieValue && matchCookieValue[1] !== "" ? decodeURIComponent(matchCookieValue[1]) : null;
    const urlValue = `https://${document.location.host}/api/v1/precisionapi/retrievetemplatedata`;
    const headers = { Cookie: `med_session=${medSessionCookieValue}`, 'Content-Type': 'application/json' };
    const StringifiedData = JSON.stringify({
      journeyId: journeyId,
      channelId: channelId
    });

    fetch(urlValue, { method: "POST", body: StringifiedData, headers: headers })
      .then((responseData) => {
        if (!responseData.ok) {
          throw new Error("Fetch Error in formularyAccessApi");
        }
        return responseData.json();
      })
      .then((jsonData) => {
        if (jsonData.status && jsonData.status === "NO_CONTENT") {
          throw new Error("No Data Found Error in formularyAccessApi");
        }
        payLoad.firstName = jsonData.firstname;
        payLoad.lastName = jsonData.lastname;
        payLoad.mainMessage = jsonData.mainmessage;
        payLoad.title = jsonData.title;
        payLoad.apiStatus = true;
        if (jsonData.precisionMessageFootnotes && Array.isArray(jsonData.precisionMessageFootnotes)) {
          const sortedFootnoteDescriptions = jsonData.precisionMessageFootnotes
            .filter(obj => /Plantable\.Below/i.test(obj.footnotelocation))
            .sort((a, b) => a.footnoteorder - b.footnoteorder)
            .map(obj => obj.footnotedescription);
          if (sortedFootnoteDescriptions.length != 0) {
            payLoad.footNoteDescription = sortedFootnoteDescriptions;
          }
        }
      })
      .catch((err) => {
        payLoad.apiStatus = false;
        console.warn('[profads] FormularyAccess API Error', err)
      })
      .finally(() => {
        formulary.displayFormularyAccess.call(this, payLoad);
      });
  }
};

export default formulary;

