let env = PageMetadata.reqHeaders.requestEnv;
env = env == "" ? "" : `.${env}`;

export function addRepConnect(data) {
  let activityId = data.activityId !== "" ? data.activityId : '69681';
  let url =  `https://api${env}.medscape.com/servicegateway/v2/auth/salesrepservice/api/v1/salesrepresentativelist/activityid/${activityId}`;
  let payLoad = {};
  payLoad.headline = data.headline;
  payLoad.template = data.template;
  payLoad.adpos = data.adpos;
  payLoad.version = data.version;
  payLoad.slotId = data.slotId;
  payLoad.disclaimer = data.disclaimer;
  payLoad.jobCode = data.jobCode;
  payLoad.activityId = activityId;
  payLoad.subject = data.subject;
  payLoad.emailBody = data.emailBody;

  let xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function() {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      // payLoad = JSON.parse(xmlHttp.responseText);
      Object.assign(payLoad, JSON.parse(xmlHttp.responseText));
      let dataCheck = payLoad.data;
      //if(Object.keys(dataCheck[0]).length == 0){
      window.profads.ads2.displayRepConnect(payLoad);

      //}
    }
    else {
      //console.log("API not working");
      //profads.ads2.displayRepConnect(payLoad);
    }
  }
  xmlHttp.open("GET", url, true); // true for asynchronous
  xmlHttp.withCredentials = true;
  xmlHttp.send(null);
}

export function displayRepConnect(data) {
  let repTitle = "Sales Representative";
  let disclaimerText = data.disclaimer != undefined ? data.disclaimer : "";
  let jobCode = data.jobCode != undefined ? data.jobCode : "";
  let subjectEmailBody = `?subject=${data.subject}&body=${data.emailBody}%0A${disclaimerText}`;
  const that = this;
  var imgPath = `https://img${env}.medscapestatic.com/pi/repconnect/${data.activityId}/`;
  var defaultRepImg = `https://img${env}.medscapestatic.com/medscape-core/ads/img/rep-profile.png`;

  var rep1 = data.data[0];
  var rep1FirstName = rep1.first_name != undefined ? rep1.first_name : "";
  var rep1LastName = rep1.last_name != undefined ? rep1.last_name : "";
  var rep1FullName = rep1FirstName + " " + rep1LastName;
  var rep1Email = rep1.email_address != undefined ? rep1.email_address : "";
  var rep1EmailDetails = rep1Email + "" + subjectEmailBody;
  var rep1Phone = rep1.phone_number != undefined ? rep1.phone_number : "";
  var rep1PhoneNumberDisplay = rep1.phone_number_formatted != undefined ? rep1.phone_number_formatted : "";
  var rep1Image = rep1.photo_name !=  ("" && undefined) ? imgPath + "" + rep1.photo_name : defaultRepImg;
  var rep1Img = rep1Image;

  var rep2 = data.data[1];
  if (Object.keys(rep2.length !== 0)) {
    var rep2FirstName = rep2.first_name != undefined ? rep2.first_name : "";
    var rep2LastName = rep2.last_name != undefined ? rep2.last_name : "";
    var rep2FullName = rep2FirstName + " " + rep2LastName;
    var rep2Email = rep2.email_address != undefined ? rep2.email_address : "";
    var rep2EmailDetails = rep2Email + "" + subjectEmailBody;
    var rep2Phone = rep2.phone_number != undefined ? rep2.phone_number : "";
    var rep2PhoneNumberDisplay = rep2.phone_number_formatted != undefined ? rep2.phone_number_formatted : "";
    var rep2Image = rep2.photo_name !=  ("" && undefined) ? imgPath + "" + rep2.photo_name : defaultRepImg;
    //console.log(rep2.photo_name !=  ("" && undefined));
    var rep2Img = rep2Image;
  }
  var title = data.headline.split("<");
  var titleUse = title[0];
  var classVersion = "v1";
  var classTemp = "rep300x400";
  var mobileTag = `<a href="tel:${rep1Phone}"  onClick="profads.ads2.repClickHandler(1)">${rep1PhoneNumberDisplay}</a>`;
  var mobileTagV2 = `<a href="tel:${rep2Phone}"  onClick="profads.ads2.repClickHandler(3)">${rep2PhoneNumberDisplay}</a>`;

  if (data.adpos == 101) {

    let css = window.profads.ads2.utils.createElement({
      name: 'link',
      attributes: [
        { rel: 'stylesheet' },
        { href: `//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/css/rep-connect-970-200.css` },
        { type: 'text/css' },
        { media: 'screen' }
      ]
    });
    window.profads.ads2.utils.appendExternalNode(css, document.head);

    var temp970 = `
        <div class="rep-connect-970-200">
        <div class="rep-connect-970-200-sec-1-main" style="margin:0;">
           <div class="rep-connect-970-200-sec-1">
              <div class="rep-connect-970-200-headline">Questions about  </div>
              <div class="rep-connect-970-200-drug">&lt;Drug Name&gt;?</div>
              <div class="rep-connect-970-200-connect">CONNECT WITH US</div>
           </div>
        </div>`;
    var temp970V1 = `
        <div class="rep-connect-970-200-representative">
        <div>
           <div class="rep-connect-970-200-image">
                <img src="${rep1Img}" width="80" />
           </div>
        </div>
        <div class="rep-connect-970-200-details">
           <div class="rep-connect-970-200-name">${rep1FullName}</div>
           <div class="rep-connect-970-200-pharma-details" style="text-align:start">${repTitle}</div>
           <div class="rep-connect-970-200-contact">
              <a href="tel:${rep1Phone}" onclick="profads.ads2.repClickHandler(1)">${rep1PhoneNumberDisplay}</a>
           </div>
        </div>
     </div>
     <div class="rep-connect-970-200-sec-3">
        <a href="mailto:${rep1EmailDetails}" onclick="profads.ads2.repClickHandler(2)">
           <div class="rep-connect-970-200-email-rep">
              <div style="margin:0px"><img src="//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/img/icon-email_2x.png" class="rep-connect-970-200-icon-email "></div>
              <div class="rep-connect-970-200-email">EMAIL YOUR REP</div>
           </div>
        </a>
        <div class="rep-connect-970-200-click-text">
           ${disclaimerText}
        </div>
     </div>
     <div class="jc">${jobCode}</div>
     </div>
        `;
    var temp970V2 = `
        <div class="rep-connect-970-200-rep-1">
        <div class="rep-connect-970-200-representative">
           <div>
              <div class="rep-connect-970-200-image">
              <img src="${rep1Img}" width="50" />
              </div>
           </div>
           <div class="rep-connect-970-200-details">
              <div class="rep-connect-970-200-name">${rep1FullName}</div>
              <div class="rep-connect-970-200-pharma-details" style="text-align:start">${repTitle}</div>
              <div class="rep-connect-970-200-contact">
                 <a href="tel:${rep1Phone}" onclick="profads.ads2.repClickHandler(1)">${rep1PhoneNumberDisplay}</a>
              </div>
           </div>
        </div>
        <div class="rep-connect-970-200-sec-3">
           <a href="mailto:${rep1EmailDetails}" onclick="profads.ads2.repClickHandler(2)">
              <div class="rep-connect-970-200-email-rep">
                 <div style="margin:0px"><img src="//img${env}.medscapestatic.com/medscape-core/ads/img/icon-email_2x.png" class="rep-connect-970-200-icon-email "></div>
                 <div class="rep-connect-970-200-email">EMAIL YOUR REP</div>
              </div>
           </a>
           <div class="rep-connect-970-200-click-text">
                ${disclaimerText}
           </div>
        </div>
     </div>
     <div class="rep-connect-970-200-rep-2">
        <div class="rep-connect-970-200-representative">
           <div>
              <div class="rep-connect-970-200-image">
                <img src="${rep2Img}" width="50" />
              </div>
           </div>
           <div class="rep-connect-970-200-details">
              <div class="rep-connect-970-200-name">${rep2FullName}</div>
              <div class="rep-connect-970-200-pharma-details" style="text-align:start">${repTitle}</div>
              <div class="rep-connect-970-200-contact">
                 <a href="tel:${rep2Phone}" onclick="profads.ads2.repClickHandler(3)">${rep2PhoneNumberDisplay}</a>
              </div>
           </div>
        </div>
        <div class="rep-connect-970-200-sec-3">
           <a href="mailto:${rep2EmailDetails}" onclick="profads.ads2.repClickHandler(4)">
              <div class="rep-connect-970-200-email-rep">
                 <div style="margin:0px"><img src="//img${env}.medscapestatic.com/medscape-core/ads/img/icon-email_2x.png" class="rep-connect-970-200-icon-email "></div>
                 <div class="rep-connect-970-200-email">EMAIL YOUR REP</div>
              </div>
           </a>
           <div class="rep-connect-970-200-click-text">
                ${disclaimerText}
           </div>
        </div>
     </div>
     <div class="jc jcv2">${jobCode}</div>
  </div>
        `;

    var html = '';
    if (data.version == "2") {
      html = temp970 + temp970V2;
    }
    else {
      html = temp970 + temp970V1;
    }
    document.getElementById("ads-pos-101").innerHTML = html;

  }
  else {

    if (data.template == "rep300x600") {
      classTemp = "rep300x600";
    }
    if (data.version == "2") {
      classVersion = "v2";
    }
    var temp1 = `<html>
    <head>
        <link rel="stylesheet" href="https://img${env}.medscapestatic.com/medscape-core/ads/css/rep-connect.css">
    </head>
    <body>
    <div class="rep ${classTemp} ${classVersion}">
    <div id="rep-top" class="rep-top">
        <div class="rep-rectangle">
                <div id="rep-title" class="rep-title">
                    ${titleUse + " &lt;DrugName&gt;?"}
                </div>
                <div id="rep-sub-title" class="rep-sub-title">
                    Connect with us
                </div>
        </div>
        
    </div>`;


    var temp2 = `
    
    <div id="rep-middle" class="rep-middle">
             <div id="rep-profile" class="rep-profile">
                <img src="${rep1Img}" width="80" />
             </div>
             <div id="rep-name" class="rep-name">
                ${rep1FullName}
             </div>
             <div id="rep-sub-name" class="rep-sub-name">
                ${repTitle}
             </div>
             
             <div id="rep-mobile" class="rep-mobile">
                ${mobileTag}
             </div>
             <div id="rep-cta" class="rep-cta">
             <a href="mailto:${rep1EmailDetails}" onClick="profads.ads2.repClickHandler(2)">
                <div id="rep-cta-content" class="rep-cta-content">
                    <img src="https://img${env}.medscapestatic.com/medscape-core/ads/img/email.png" height="16px"/>EMAIL YOUR REP
                </div>
             </a>
             </div>
             <div id="rep-agree" class="rep-agree">
                ${disclaimerText}
             </div>
    </div>
    `;

    var temp3 = `
        <div id="rep-last" class="rep-last">
            <div id="rep-top-rep" class="rep-top-rep">
                    <div id="rep-left" class="rep-left">
                        <div class="rep-profile">
                            <img src="${rep1Img}" width="50" />
                        </div>
                    </div>
                    <div id="rep-right" class="rep-right">
                         <div class="rep-name">
                         ${rep1FullName}
                         </div>
                         <div class="rep-sub-name">
                             ${repTitle}
                         </div>
                         <div class="rep-mobile">
                             ${mobileTag}
                         </div>
                    </div>
                     <div id="rep-cta" class="rep-cta">
                     <a href="mailto:${rep1EmailDetails}" onClick="profads.ads2.repClickHandler(2)">
                        <div id="rep-cta-content" class="rep-cta-content">
                            <img src="https://img${env}.medscapestatic.com/medscape-core/ads/img/email.png"  height="16px"/>EMAIL YOUR REP
                        </div>
                     </a>
                    </div>
                     <div id="rep-agree" class="rep-agree">
                        ${disclaimerText}
                 </div>
            </div>
            <div id="rep-divider">
            </div>
            <div id="rep-second-rep" class="rep-top-rep">
                    <div id="rep-left" class="rep-left">
                        <div class="rep-profile">
                            <img src="${rep2Img}" width="50" />
                        </div>
                    </div>
                    <div id="rep-right" class="rep-right">
                         <div class="rep-name">
                           ${rep2FullName}
                         </div>
                         <div class="rep-sub-name">
                            ${repTitle}
                         </div>
                         <div class="rep-mobile">
                            ${mobileTagV2}
                         </div>
                    </div>
                     <div id="rep-cta" class="rep-cta">
                      <a href="mailto:${rep2EmailDetails}" onClick="profads.ads2.repClickHandler(4)">
                        <div id="rep-cta-content" class="rep-cta-content">
                            <img src="https://img${env}.medscapestatic.com/medscape-core/ads/img/email.png" height="16px"/>EMAIL YOUR REP
                        </div>
                     </a>
                    </div>
                     <div id="rep-agree" class="rep-agree">
                        ${disclaimerText}
                 </div>
            </div>
        </div>
        `;

    var temp4 =
`
<div class="rep-jc">${jobCode}</div>
 </div>
</body>
</html>
`;
    var html = '';
    if (data.version == "2") {
      html = temp1 + temp3 + temp4;
    }
    else {
      html = temp1 + temp2 + temp4;
    }
    document.getElementById(data.slotId).innerHTML = html;

  }
}

