/* eslint-disable no-mixed-spaces-and-tabs */
// ME web code

// ME enable variable

let MEADS = {
  init: function() {
    if (enableME && window.PageMetadata.userSegVars != undefined) {
      if (window.PageMetadata.userSegVars.dt != undefined) {
        webmd.tpsvc.promises.push(MEADS.addSlotLevelMEKeys);
      }
    }
  },
  externals: [
    { id: 'Medscape-101', size: [ 728, 90 ], pos: '101' },
    { id: 'Medscape-122', size: [ [ 300, 600 ], [ 300, 250 ] ], pos: '122' },
    { id: 'Medscape-910', size: [ 300, 250 ], pos: '910' },
    { id: 'Medscape-141', size: [ 728, 90 ], pos: '141' },
    { id: 'Medscape-1004', size: [ [ 320, 50 ], [ 300, 50 ] ], pos: '1004' },
    { id: 'Medscape-1122', size: [ [ 320, 50 ], [ 300, 50 ], [ 300, 250 ] ], pos: '1122' }
  ],
  //npi: window.PageMetadata.userSegVars.dt != undefined ? window.PageMetadata.userSegVars.dt : 0,
  serverUrls: {
    dev: 'dev.mdscpxchg.com',
    prod: 'serving.mdscpxchg.com'
  },
  serverProtocol: 'https',
  endpoint: '/ad',
  minOrd: 10000000,
  maxOrd: 99999999,
  slots: [],

  getOrd: function() { return Math.floor(Math.random() * this.maxOrd) + this.minOrd;},
  getUrl: function(externals, npi) {
    const me = this;
    let ord = typeof window.parent.s_pageview_id == "string" ? window.parent.s_pageview_id : me.getOrd() + "" + me.getOrd();

    const ids = me.getExternalList (externals, 'id');
    const url = `${me.server}${me.endpoint}?external_ids=${ids.join(',')}&npi_hashed=${npi}&ord=${ord}`;
    return url;
  },
  getPositions: function() {
		  var pos = [];
		  MEADS.externals.forEach(function(elem) {
      if (ads2_ignore[elem.pos] != "true") {
        if (elem.pos.length == 3 && (window.PageMetadata.reqHeaders.device == "PC" || window.PageMetadata.reqHeaders.device == "IPAD")) {
          pos.push(elem.pos);
        }
        if (window.PageMetadata.reqHeaders.device == "MOBILE" && elem.pos.length == 4) {
          pos.push(elem.pos);
        }
      }
		 });
		 return pos;
  },
  getOrder: function() {
    let ord = typeof window.parent.s_pageview_id == "string" ? window.parent.s_pageview_id : this.getOrd() + "" + this.getOrd();
    return ord;
  },
  getAdHaivngPositions: function(pos, id) {
		  var list = [];
		  var pos = pos;
		  MEADS.externals.forEach(function(external) {
			  if (pos.indexOf(external.pos) != -1) {
				  list.push(external.id);
			  }
		  });
    return list;
	  },
  addSlotLevelMEKeys: function() {
    return new Promise((resolve, reject) => {
      var getEnv = window.PageMetadata.webSegVars.envp === 'prod' ? 'prod' : 'dev';
      var getNPI;
      if (window.PageMetadata.userSegVars != undefined) {
        getNPI = window.PageMetadata.userSegVars.dt != undefined ? window.PageMetadata.userSegVars.dt : 0;
      }

      MEADS.serverUrl = MEADS.serverUrls[getEnv];
      MEADS.server = MEADS.serverProtocol + '://' + MEADS.serverUrl;
      const npi = getNPI;

      var i = 0;
      var newpos = [];
      var newPositions = {};
      var iponPos = MEADS.getPositions();
      $.each(webmd.ads2.ads, function(id, adSettings) {
        if (Object.keys(oldPositions).indexOf(id) == -1) {
          oldPositions[id] = { pos: adSettings.pos, slot: adSettings.slot };
          newPositions[id] = { pos: adSettings.pos, slot: adSettings.slot };
          newpos.push(adSettings.pos + "");
          oldMEpos.push(adSettings.pos + "")
        }

        i++;
        if (Object.keys(webmd.ads2.ads).length == i) {
          if (newpos.length === 0) {
          //used while refreshing
          //console.log("check if entering only refresh scenario");
            newpos = oldMEpos;
          }
          var getURLMescapeIds = MEADS.getAdHaivngPositions(newpos);
          if (getURLMescapeIds.length == 0) {
            return;
          }
          const url = `${MEADS.server}${MEADS.endpoint}?external_ids=${getURLMescapeIds.join(',')}&npi_hashed=${getNPI}&ord=${MEADS.getOrder()}`;
          var winners = {};
          $.ajax({
            url: url,
            async: true,
            timeout: 750,
            error: function() {
              console.warn("TimeOut exception");
              reject('ME Promise rejected1.');
            },
            success: function(data) {
              var data = data;
              if (Object.keys(data).length > 0) {
                const ord = [ data.ord ];
                var length = Object.keys(data.winners).length;
                var win = data.winners;
                var matchedData = data.matched_data;
                for (var i = 0; i < length;i++) {
                  var impID = win[i]['impid'];
                  var posID = win[i]['external_id'];
                  var pos = posID.split('-')[1];
                  win[i]['ord'] = ord;
                  winners[pos] = win[i];
                }
                var matched_data = {};
                for (var m = 0; m < data.matched_data.length;m++) {
                //console.log(data.matched_data[i]);
                  var extobj = data.matched_data[m];
                  var extId = extobj.external_id;
                  var pos = extId.split('-')[1];
                  matched_data[pos] = extobj.deal_ids.toString();
                }
                $.each(newPositions, function(id, adSettings) {
                  var slot = adSettings.slot;
                  var keyValueObj = {};
                  var matchedData =  matched_data[adSettings.pos];
                  if (typeof matchedData == "string" && slot !== 'undefined') {
                    keyValueObj["bid_ids"] = matchedData;
                    slot.updateTargetingFromMap(keyValueObj);
                  }
                  const winner = winners[adSettings.pos];
                  if (typeof winner == "object" && slot !== 'undefined') {

                    const impid = [ winner.impid ];
                    const extid = winner.external_id;
                    const moa = [ winner.winner_id ];
                    const mop = [ winner.price ];
                    const ord = winner.ord;
                    const size = [ winner.w + 'x' + winner.h ];
                    keyValueObj["rord"] = ord;
                    keyValueObj["mcs"] = size;
                    keyValueObj["imid"] = impid;
                    keyValueObj["moa"] = moa;
                    keyValueObj["deal_id"] = [ winner.deal_id ];
                    keyValueObj["bid_ids"] = matchedData;
                    webmd.ads2.addSlotRenderEndedCallback(id, profads.ads2.removeMEKeys);
                    keyValueObj["mop"] = mop;
                    keyValueObj["oaw"] = "me";
                    slot.updateTargetingFromMap(keyValueObj);
                  }
                  resolve("resolved ME promise");
                })
              }
            }  //success end
          })  //ajax end
        }
      }) //positions iterations
      // Reject the Promise when timeout
      setTimeout(() => {
        reject('ME Promise rejected2.');
      }, 750);

    })//promise
  }


};
// end of ME
export { MEADS as default };
