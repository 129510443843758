function SetProfSizes() {
  //Init size setting.  Possible to override on page specific JS templates before display
  webmd.ads2.sizes = {
    '101': [
      [ 728, 90 ],
      [ 970, 250 ],
      [ 970, 90 ],
      [ 970, 200 ],
      'fluid'
    ],
    '104': [
      [ 728, 90 ],
      [ 970, 90 ],
      [ 970, 250 ],
      'fluid'
    ],
    '105': [
      [ 1, 2 ],
      'fluid'
    ],
    '107': [
      [ 2, 14 ],
      'fluid'
    ],
    '122': [
      [ 300, 250 ],
      [ 300, 600 ],
      [ 300, 350 ],
      'fluid'
    ],
    '127': [
      [ 300, 250 ],
      [ 300, 350 ],
      [ 300, 500 ],
      [ 300, 600 ],
      [ 300, 1050 ],
      'fluid'
    ],
    '124': [
      [ 300, 250 ]
    ],
    '126': [
      [ 300, 250 ]
    ],
    '141': [
      [ 728, 90 ],
      [ 728, 91 ],
      'fluid'
    ],
    '142': [
      [ 2, 4 ],
      [ 300, 250 ],
      'fluid'
    ],
    '145': [
      [ 728, 100 ],
      [ 728, 92 ],
      [ 2, 7 ],
      'fluid'
    ],
    '201': [
      'fluid',
      [ 2, 1 ]
    ],
    '202': [
      'fluid',
      [ 2, 2 ]
    ],
    '203': [
      'fluid',
      [ 2, 2 ]
    ],
    '319': [
      [ 10, 10 ]
    ],
    '420': [
      [ 2, 3 ],
      'fluid'
    ],
    '421': [
      [ 1, 4 ],
      [ 1, 12 ],
      'fluid'
    ],
    '425': [
      [ 4, 9 ],
      'fluid'
    ],
    '520': [
      [ 2, 3 ],
      'fluid'
    ],
    '521': [
      [ 2, 11 ],
      'fluid'
    ],
    '523': [
      [ 2, 15 ],
      'fluid'
    ],
    '525': [
      [ 4, 9 ],
      'fluid'
    ],
    '615': [
      [ 10, 10 ]
    ],
    '622': [
      [ 2, 9 ],
      'fluid'
    ],
    '632': [
      [ 2, 9 ],
      'fluid'
    ],
    '633': [
      [ 2, 9 ],
      'fluid'
    ],
    '712': [
      [ 728, 90 ],
      [ 970, 90 ],
      'fluid'
    ],
    '722': [
      [ 2, 9 ],
      'fluid'
    ],
    '800': [
      [ 2, 10 ]
    ],
    '820': [
      [ 4, 1 ]
    ],
    '904': [
      'fluid',
      [ 2, 18 ]
    ],
    '906': [
      'fluid',
      [ 2, 18 ]
    ],
    '907': [
      'fluid',
      [ 2, 18 ]
    ],
    '910': [
      [ 300, 250 ],
      [ 300, 251 ],
      'fluid'
    ],
    '912': [
      [ 2, 8 ]
    ],
    '916': [
      [ 2, 18 ],
      'fluid'
    ],
    '917': [
      [ 2, 18 ],
      'fluid'
    ],
    '940': [
      'fluid',
      [ 2, 16 ]
    ],
    '941': [
      'fluid',
      [ 2, 16 ]
    ],
    '970': [
      [ 1, 12 ],
      'fluid'
    ],
    '971': [
      [ 1, 13 ],
      'fluid'
    ],
    '309': [
      [ 5, 1 ],
      'fluid'
    ],
    '148': [
      [ 4, 10 ],
      'fluid'
    ],
    '424': [
      [ 4, 9 ],
      'fluid'
    ],
    '1424': [
      [ 4, 9 ],
      'fluid'
    ],
    '1004': [
      [ 300, 50 ],
      [ 320, 50 ],
      'fluid'
    ],
    '1005': [
      [ 1, 2 ],
      'fluid'
    ],
    '1007': [
      [ 2, 8 ]
    ],
    '1122': [
      [ 300, 50 ],
      [ 300, 250 ],
      [ 300, 400 ],
      [ 300, 51 ],
      [ 320, 50 ],
      [ 320, 51 ],
      'fluid'
    ],
    '1145': [
      [ 320, 80 ],
      [ 375, 80 ],
      [ 320, 52 ],
      [ 300, 52 ],
      [ 2, 7 ],
      'fluid'
    ],
    '1420': [
      [ 2, 3 ],
      [ 300, 254 ],
      'fluid'
    ],
    '1421': [
      [ 1, 12 ],
      'fluid'
    ],
    '1425': [
      [ 4, 9 ],
      'fluid'
    ],
    '1520': [
      [ 2, 3 ],
      [ 1, 12 ],
      [ 300, 254 ],
      [ 300, 250 ],
      [ 300, 340 ],
      [ 375, 340 ],
      [ 300, 500 ],
      [ 375, 500 ],
      'fluid'
    ],
    '1521': [
      [ 2, 11 ],
      'fluid'
    ],
    '1522': [
      [ 300, 250 ],
      'fluid'
    ],
    '1523': [
      [ 2, 15 ],
      'fluid'
    ],
    '1525': [
      [ 4, 9 ],
      'fluid'
    ],
    '1622': [
      [ 2, 9 ],
      'fluid'
    ],
    '1632': [
      [ 2, 9 ],
      'fluid'
    ],
    '1633': [
      [ 2, 9 ],
      'fluid'
    ],
    '1703': [
      [ 300, 250 ],
      'fluid'
    ],
    '1712': [
      [ 300, 250 ],
      'fluid'
    ],
    '1722': [
      [ 2, 9 ],
      'fluid'
    ],
    '1800': [
      [ 2, 10 ]
    ],
    '1909': [
      'fluid',
      [ 2, 5 ]
    ],
    '1970': [
      [ 1, 12 ],
      'fluid'
    ],
    '1971': [
      [ 1, 13 ],
      'fluid'
    ],
    '2017': [
      [ 1, 4 ],
      'fluid'
    ],
    '2122': [
      [ 300, 250 ]
    ],
    '2520': [
      [ 1, 5 ]
    ],
    '2420': [
      [ 2, 3 ]
    ],
    '1309': [
      [ 5, 1 ],
      'fluid'
    ]
  };
  if (isDD && !isEmed) {
    webmd.ads2.sizes[122] = [
      [ 300, 401 ],
      [ 300, 250 ],
      [ 300, 600 ],
      [ 300, 350 ],
      'fluid'
    ];
  }
  if (_isAnArticle || isEmed) {
    webmd.ads2.sizes[420] = [
      [ 1, 3 ],
      [ 2, 3 ],
      'fluid'
    ];
  }
  if (pageCurrentURL.indexOf('/mtv/') !== -1) {
    webmd.ads2.sizes[122] = [
      [ 300, 250 ],
      [ 300, 600 ],
      [ 300, 350 ],
      [ 160, 600 ],
      'fluid'
    ];
  }
  if (profads.ads2.utils.isSlideShow()) {
    if (profads.ads2.utils.hasEcdQuery()) {
      webmd.ads2.sizes[1122] = [
        [ 300, 250 ],
        'fluid'
      ];
    } else {
      if (slideShowVars.infiniteScroll) {
        let targetString = slideShowVars.lazyLoadInt !== 0 ? `${slideShowVars.lazyLoadInt}slides` : 'default';
        let targetValue = `cr2019_${targetString}`;
        webmd.ads2.setPageTarget('tex', targetValue);
        webmd.ads2.sizes[1122] = [
          [ 300, 250 ],
          [ 300, 400 ],
          [ 300, 50 ],
          [ 300, 51 ],
          [ 320, 50 ],
          [ 320, 51 ],
          'fluid'
        ];
      }
    }
    webmd.ads2.sizes[1522] = [
      [ 300, 250 ],
      'fluid'
    ];
  }
  if (pageCurrentURL.indexOf('activitytracker') != -1) {
    webmd.ads2.sizes[508] = [ [ 5, 6 ] ]
  }
  if (pageCurrentURL.indexOf('search.') != -1) {
    webmd.ads2.sizes[420] = [
      [ 1, 3 ],
      [ 2, 3 ],
      [ 1, 12 ],
      'fluid'
    ];
    webmd.ads2.sizes[1420] = [
      [ 1, 3 ],
      [ 2, 3 ],
      [ 1, 12 ],
      'fluid'
    ];
  }

  if ((isEmed || _isAnArticle || _isRecap || PageMetadata.webSegVars.pc === 'hp') && PageMetadata.reqHeaders.device == "MOBILE") {
    webmd.ads2.sizes[1122] = [
      [ 300, 250 ],
      [ 300, 400 ],
      [ 300, 50 ],
      [ 300, 51 ],
      [ 320, 50 ],
      [ 320, 51 ],
      'fluid'
    ];
  }
  if (PageMetadata.webSegVars.pc === 'rc' && s_responsive_design) {

    webmd.ads2.sizes[420] = [
      [ 2, 4 ],
      'fluid'
    ];
    webmd.ads2.sizes[141] = [
      [ 728, 90 ],
      [ 728, 91 ],
      [ 2, 4 ],
      [ 300, 250 ],
      'fluid'
    ];
    webmd.ads2.sizes[142] = [
      [ 2, 4 ],
      [ 300, 250 ],
      'fluid'
    ];
    webmd.ads2.sizes[520] = [
      [ 2, 4 ],
      'fluid'
    ];
    webmd.ads2.sizes[1420] = [
      [ 2, 4 ],
      'fluid'
    ];
    webmd.ads2.sizes[1712] = [
      [ 2, 4 ],
      'fluid'
    ];
  }
  if (s_responsive_design) {
    /* Set sizes for emed diesease and condition articles to only pass long form into 420 and 1420 */
    if (isEmed || pageCurrentURL.match(/(viewarticle|artikelansicht|voirarticle|verarticulo|verartigo)/)) {

      webmd.ads2.sizes[420] = [
        [ 2, 3 ],
        'fluid'
      ];
      webmd.ads2.sizes[520] = [
        [ 2, 3 ],
        'fluid'
      ];
      webmd.ads2.sizes[1420] = [
        [ 2, 3 ],
        [ 1, 12 ],
        [ 300, 254 ],
        'fluid'
      ];
    }
    // new native positions for drug monographs
    if (isDD) {
      webmd.ads2.sizes[1421] = [
        [ 1, 12 ],
        'fluid'
      ];
      //add 1122 sizes for new monograph position PPE-155031
      webmd.ads2.sizes[1122] = [
        [ 300, 250 ],
        [ 300, 400 ],
        [ 300, 50 ],
        [ 300, 51 ],
        [ 320, 50 ],
        [ 320, 51 ],
        'fluid'
      ];
    }
  }
  webmd.ads2.sizes[909] = [
    'fluid',
    [ 2, 5 ]
  ];
  if (_isRecap) {
    webmd.ads2.sizes[1145] = [
      [ 375, 80 ],
      [ 320, 80 ],
      [ 320, 50 ],
      [ 300, 50 ],
      [ 320, 52 ],
      [ 300, 52 ],
      [ 2, 7 ],
      'fluid'
    ];
  }

  if (mobOptCheck == true) {
    window.textDriverOptimized = mobOptCheck;
  }

  if (window.textDriverOptimized) {
    webmd.ads2.sizes[145] = [
      'fluid',
      [ 728, 100 ],
      [ 728, 92 ],
      [ 2, 7 ]
    ];
    webmd.ads2.sizes[1145] = [
      'fluid',
      [ 320, 80 ],
      [ 375, 80 ],
      [ 320, 52 ],
      [ 300, 52 ],
      [ 2, 7 ]
    ];
  }
  if (_isODP) {
    if (PageMetadata.webSegVars.pc == "indexpage") {
      webmd.ads2.sizes[1122] = [
        [ 300, 250 ],
        [ 300, 400 ],
        [ 300, 50 ],
        [ 300, 51 ],
        [ 320, 50 ],
        [ 320, 51 ],
        'fluid'
      ];
    }
    if (PageMetadata.webSegVars.pc == "content") {
      webmd.ads2.sizes[1909] = [
        'fluid',
        [ 2, 5 ]
      ];
      webmd.ads2.sizes[420] = [
        [ 2, 3 ],
        'fluid'
      ];
      webmd.ads2.sizes[520] = [ [ 2, 3 ], 'fluid' ];
    }
  }
  if (PageMetadata.reqHeaders.device === 'PC' || PageMetadata.reqHeaders.device === 'IPAD') {
    webmd.ads2.sizes['918'] = [
      [ 2, 5 ],
      'fluid'
    ];
    webmd.ads2.sizes['919'] = [
      [ 1, 18 ],
      'fluid'
    ];
  };
  if (PageMetadata.reqHeaders.device === 'MOBILE') {
    webmd.ads2.sizes['1918'] = [
      [ 2, 5 ],
      'fluid'
    ];
    webmd.ads2.sizes['1919'] = [
      [ 1, 18 ],
      'fluid'
    ];
  };
  webmd.ads2.sizes['520'].push([ 400, 340 ], [ 400, 500 ], [ 300, 255 ]);
  if (PageMetadata.webSegVars.pc === 'hp' && PageMetadata.reqHeaders.device === 'PC') {
    webmd.ads2.sizes[122].push([ 400, 500 ], [ 400, 340 ]);
  };
  if (PageMetadata.webSegVars.pc === 'hp' && PageMetadata.reqHeaders.device === 'MOBILE') {
    webmd.ads2.sizes[1122].push([ 300, 500 ], [ 300, 340 ]);
  };
  if (PageMetadata.reqHeaders.geoc === 'CA') {
    webmd.ads2.sizes[1004].push([ 300, 250 ]);
  }
  return webmd.ads2.sizes;
}
export { SetProfSizes as default };

