function dynamicTargetkeys(key, value) {
  //uri key
  webmd.ads2.setPageTarget("uri", window.location.pathname);
  //fpt key
  if ($.cookie("fpt") == null) {
    if (typeof isFPFEligible == "undefined") {
      $.cookie("fpt", "0", { domain: ".medscape.com", path: "/" });
      $.cookie("fpt", "0", { domain: ".medscape.org", path: "/" });
    } else if (isFPFEligible == false) {
      $.cookie("fpt", "faf", { domain: ".medscape.com", path: "/" });
      $.cookie("fpt", "faf", { domain: ".medscape.org", path: "/" });
    } else if (isFPFEligible == true) {
      $.cookie("fpt", "fpf", { domain: ".medscape.com", path: "/" });
      $.cookie("fpt", "fpf", { domain: ".medscape.org", path: "/" });
    } else {
      $.cookie("fpt", "0", { domain: ".medscape.com", path: "/" });
      $.cookie("fpt", "0", { domain: ".medscape.org", path: "/" });
    }
  }
  var fpt = $.cookie("fpt");
  webmd.ads2.setPageTarget("fpt", fpt);
  //pg key
  webmd.ads2.setPageTarget("pg", profads.ads2.getPageNumber()); //calling the custom pageNumber function to get pageNumber
  //aamid key
  var auu = 0;
  $.cookie("aam_uuid") == null ? null : (auu = $.cookie("aam_uuid"));
  webmd.ads2.setPageTarget("aamid", auu);
  //ecd key
  if (profads.ads2.getECD()) {
    webmd.ads2.setPageTarget("ecd", profads.ads2.getECD());
  }
  //usps and usoo keys
  var usprivacycookie = $.cookie("usprivacy");
  if (usprivacycookie) {
    try {
      var usoo = usprivacycookie.charAt(2);
      webmd.ads2.setPageTarget("usps", usprivacycookie);
      webmd.ads2.setPageTarget("usoo", usoo);
    } catch (e) {
      console.error(
        "Something went wrong while fetching the usprivacy cookie",
        e
      );
    }
  }
  //medtab key
  if (isEmed || isDD) {
    var hash = location.hash;
    var getPageNumber = hash.split("#")[1];
    console.log("getPageNumber", getPageNumber);
    var number = getPageNumber != undefined ? getPageNumber : 0;
    webmd.ads2.setPageTarget("medtab", number);
  }
  //tug key
  if (typeof networkGate !== "undefined" && networkGate !== null) {
    let tug =
      typeof networkGate.bundleId !== "undefined" && networkGate.bundleId != ""
        ? networkGate.bundleId.toString()
        : "0";
    webmd.ads2.setPageTarget("tug", tug);
  }
  //dpt key
  if (_isODP && PageMetadata.webSegVars.pc == "indexpage") {
    let urlObj = profads.ads2.getUrlVars();
    let mainType = Object.keys(urlObj)[0]; //ctype, ... Dynamically get the type.
    console.log(urlObj);
    const keyArray = [ "ctype", "type" ]; // array with all the mainTypes.. insert list of possible types from coming User stories.

    if (keyArray.indexOf(mainType) !== -1) {
      let mainTypeValue = decodeURI(urlObj[Object.keys(urlObj)[0]]);
      let subTypeValue = decodeURI(urlObj[Object.keys(urlObj)[1]]); //tType.. getting it from URL
      webmd.ads2.setPageTarget("dpt", mainTypeValue + "," + subTypeValue);
    } else {
      webmd.ads2.setPageTarget("dpt", 0);
    }
  }
  //dmp key
  var aam = $.cookie("aam") == null ? $.cookie("AAM") : $.cookie("aam");
  var aamSt = "";
  if (key == "dmp" && aam == null) {
    // first page load aam not available if dmp available add it to cust_params
    var uniqueDMPValues = value.filter(profads.ads2.uniqueArray);
    webmd.ads2.setPageTarget(key, uniqueDMPValues);
  }
  if (aam != null) {
    //if aam cookie available
    aam = aam.toLowerCase();
    aamSt = aam.split("aam=").filter((e) => e != "");
    if (key == "dmp") {
      // already existing dmp key value add the cookie at the end.
      var allDMPkeys = value + "," + aamSt;
      var allDMPkeysArray = allDMPkeys.split(",");
      var uniqueAAMDMP = allDMPkeysArray.filter(profads.ads2.uniqueArray);
      webmd.ads2.setPageTarget("dmp", uniqueAAMDMP);
      findDmp = 1;
    }
    if (findDmp == 0) {
      //if dmp key not available then add dmp key with aam cookie value.
      var uniqueAAM = aamSt.filter(profads.ads2.uniqueArray);
      webmd.ads2.setPageTarget("dmp", uniqueAAM);
    }
  }

  //iaf key
  if (sessionStorage.getItem("url") === null) {
    sessionStorage.setItem("url", window.location.href);
  }

  if (window.location.href == sessionStorage.getItem("url")) {
    webmd.ads2.setPageTarget("iaf", "1");
    sessionStorage.setItem("clicked", "1");
  }
  if (
    sessionStorage.getItem("url") !== null &&
    window.location.href.indexOf("#") != "-1" &&
    sessionStorage
      .getItem("url")
      .indexOf(window.location.href.split("#")[0]) !== -1
  ) {
    webmd.ads2.setPageTarget("iaf", "1");
  } else if (
    sessionStorage.getItem("url") !== null &&
    window.location.href.indexOf("_") != "-1" &&
    sessionStorage
      .getItem("url")
      .indexOf(window.location.href.split("_")[0]) !== -1
  ) {
    webmd.ads2.setPageTarget("iaf", "1");
  } else if (
    sessionStorage.getItem("url") !== null &&
    sessionStorage.getItem("clicked") == "1" &&
    window.location.href != sessionStorage.getItem("url")
  ) {
    webmd.ads2.setPageTarget("iaf", "0");
  }
}

export { dynamicTargetkeys as default };

