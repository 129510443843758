

export function SetSubDOMenv() {
  let subDOMenv;
  if (typeof PageMetadata !== "undefined") {
    if (PageMetadata.reqHeaders.requestEnv == '') {
      subDOMenv = PageMetadata.reqHeaders.requestEnv;
    } else {
      subDOMenv = "." + PageMetadata.reqHeaders.requestEnv;
    }
  } else {
    if (/qa02/i.test(pageCurrentURL)) {
      if (/staging/i.test(pageCurrentURL)) {
        subDOMenv = ".staging.qa02";
      } else {
        subDOMenv = ".qa02";
      }
    } else if (/qa01/i.test(pageCurrentURL)) {
      if (/staging/i.test(pageCurrentURL)) {
        subDOMenv = ".staging.qa01";
      } else {
        subDOMenv = ".qa01";
      }
    } else if (/qa00/i.test(pageCurrentURL)) {
      if (/staging/i.test(pageCurrentURL)) {
        subDOMenv = ".staging.qa00";
      } else {
        subDOMenv = ".qa00";
      }
    } else if (/proddev/i.test(pageCurrentURL)) {
      subDOMenv = ".proddev";
    } else if (/staging/i.test(pageCurrentURL)) {
      subDOMenv = ".staging";
    } else {
      subDOMenv = "";
    }
  }
  return subDOMenv;
}

export function DetectUA(DevTypeObj) {
  let reqHeaderTest;
  if (typeof PageMetadata !== "undefined") {
    reqHeaderTest = PageMetadata.reqHeaders.device;
  } else if (typeof AjaxTargetKeys !== "undefined") {
    reqHeaderTest = AjaxTargetKeys.reqHeaders.device;
  }
  if (typeof reqHeaderTest !== "undefined") {
    if (/mobile/i.test(reqHeaderTest.toLowerCase())) {
      DevTypeObj.isMobile = true;
      DevTypeObj.isTablet = false;
      DevTypeObj.isDesktop = false;
    } else if (/ipad/i.test(reqHeaderTest.toLowerCase())) {
      DevTypeObj.isMobile = false;
      DevTypeObj.isTablet = true;
      DevTypeObj.isDesktop = false;
    } else if (/pc/i.test(reqHeaderTest.toLowerCase())) {
      DevTypeObj.isMobile = false;
      DevTypeObj.isTablet = false;
      DevTypeObj.isDesktop = true;
    } else {
      DevTypeObj.isMobile = false;
      DevTypeObj.isTablet = false;
      DevTypeObj.isDesktop = true;
    }
    return DevTypeObj;
  }
}

export function DetectSubD_D(SubD_D_Obj) {
  let subDOMenv = profads.ads2.SetSubDOMenv();
  let isSearchDomain = location.host.split('.')[0] === 'search',
    query = location.search,
    queryArray = query.indexOf('&') !== -1 ? query.split('&') : [ query.substr(1, 1) ],
    isCMESearch = queryArray.filter(function(val) {
      return val === 'plr=edu';
    }).length === 1 && isSearchDomain;
  let ProfMTVDOM = new RegExp('www' + subDOMenv + '.medscape.com/mtv');
  let ProfNewsDOM = new RegExp('www' + subDOMenv + '.medscape.com|wp' + subDOMenv + '.medscape.com|search' + subDOMenv + '.medscape.com|profreg' + subDOMenv + '.medscape.com|directory' + subDOMenv + '.medscape.com');
  let ProfLoginDOM = new RegExp('login' + subDOMenv + '.medscape.com');
  let ProfRefDOM = new RegExp('reference' + subDOMenv + '.medscape.com|emedicine' + subDOMenv + '.medscape.com');
  let ProfMfrDOM = new RegExp('francais' + subDOMenv + '.medscape.com|search' + subDOMenv + '.medscape.com/search/fr');
  let ProfMdeDOM = new RegExp('deutsch' + subDOMenv + '.medscape.com|search' + subDOMenv + '.medscape.com/search/de');
  let ProfMdePraxDOM = new RegExp('praxis' + subDOMenv + '.medscapemedizin.de');
  let ProfUGCDOM = new RegExp('boards' + subDOMenv + '.medscape.com');
  let ProfMesDOM = new RegExp('espanol' + subDOMenv + '.medscape.com|search' + subDOMenv + '.medscape.com/search/es');
  let ProfMptDOM = new RegExp('portugues' + subDOMenv + '.medscape.com|search' + subDOMenv + '.medscape.com/search/pt');
  let ProfEduDOM = new RegExp('www' + subDOMenv + '.medscape.org');
  let ProfEduFRDOM = new RegExp('www' + subDOMenv + '.medscape.org/francais');
  let ProfEduDEDOM = new RegExp('www' + subDOMenv + '.medscape.org/deutsch');
  let ProfEduESDOM = new RegExp('www' + subDOMenv + '.medscape.org/espanol');
  let ProfEduPTDOM = new RegExp('www' + subDOMenv + '.medscape.org/portugues');
  let ProfKaleoDOM = new RegExp('www' + subDOMenv + '.medscape.com/answers');
  let ProfODP = new RegExp('decisionpoint' + subDOMenv + '.medscape.com');

  SubD_D_Obj.ProfPromoNews = false;
  SubD_D_Obj.ProfPromoLogin = false;
  SubD_D_Obj.ProfPromoRef = false;
  SubD_D_Obj.ProfMFR = false;
  SubD_D_Obj.ProfMDENews = false;
  SubD_D_Obj.ProfMDEPraxis = false;
  SubD_D_Obj.ProfUGC = false;
  SubD_D_Obj.ProfMES = false;
  SubD_D_Obj.ProfEdu = false;
  SubD_D_Obj.ProfEduFR = false;
  SubD_D_Obj.ProfEduDE = false;
  SubD_D_Obj.ProfEduES = false;
  SubD_D_Obj.ProfEduPT = false;
  SubD_D_Obj.ProfMPT = false;
  SubD_D_Obj.ProfMTV = false;
  SubD_D_Obj.ProfKaleoDOM = false;
  SubD_D_Obj.ProfODP = false;

  if (typeof(locale) == "undefined") {
    locale = "us";
  }
  if (ProfLoginDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfPromoLogin = true;
  } else if (ProfRefDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfPromoRef = true;
  } else if (ProfMfrDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfMFR = true;
  } else if (ProfMdeDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfMDENews = true;
  } else if (ProfMdePraxDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfMDEPraxis = true;
  } else if (ProfUGCDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfUGC = true;
  } else if (ProfMesDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfMES = true;
  } else if (ProfEduFRDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfEduFR = true;
  } else if (ProfEduDEDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfEduDE = true;
  } else if (ProfEduESDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfEduES = true;
  } else if (ProfEduPTDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfEduPT = true;
  } else if (ProfMptDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfMPT = true;
  } else if (ProfKaleoDOM.test(pageCurrentURL)) {
    SubD_D_Obj.ProfKaleoDOM = true;
  } else if (ProfODP.test(pageCurrentURL)) {
    SubD_D_Obj.ProfODP = true;
  } else if (ProfNewsDOM.test(pageCurrentURL)) {
    if (ProfMTVDOM.test(pageCurrentURL)) {
      SubD_D_Obj.ProfMTV = true;
    } else if (locale == "fr") {
      SubD_D_Obj.ProfMFR = true;
    } else if (locale == "de") {
      SubD_D_Obj.ProfMDENews = true;
    } else if (locale == "pt") {
      SubD_D_Obj.ProfMPT = true;
    } else if (locale == "es") {
      SubD_D_Obj.ProfMES = true;
    } else if (isCMESearch) {
      SubD_D_Obj.ProfEdu = true;
    } else {
      SubD_D_Obj.ProfPromoNews = true;
    }
  } else if (ProfEduDOM.test(pageCurrentURL)) {
    if (locale == "fr") {
      SubD_D_Obj.ProfEduFR = true;
    } else if (locale == "de") {
      SubD_D_Obj.ProfEduDE = true;
    } else if (locale == "pt") {
      SubD_D_Obj.ProfEduPT = true;
    } else if (locale == "es") {
      SubD_D_Obj.ProfEduES = true;
    } else {
      SubD_D_Obj.ProfEdu = true;
    }
  }

  else {
    SubD_D_Obj.ProfMTV = false;
    SubD_D_Obj.ProfPromoNews = false;
    SubD_D_Obj.ProfPromoLogin = false;
    SubD_D_Obj.ProfPromoRef = false;
    SubD_D_Obj.ProfEdu = false;
    SubD_D_Obj.ProfMFR = false;
    SubD_D_Obj.ProfMDENews = false;
    SubD_D_Obj.ProfMDEPraxis = false;
    SubD_D_Obj.ProfUGC = false;
    SubD_D_Obj.ProfMES = false;
    SubD_D_Obj.ProfMPT = false;
    SubD_D_Obj.ProfEduFR = false;
    SubD_D_Obj.ProfEduDE = false;
    SubD_D_Obj.ProfEduES = false;
  }
  return SubD_D_Obj;
}

export function TestAdUnitL1L2(DevTObj, SubDDObj) {
  if (DevTObj.isDesktop || DevTObj.isTablet) {
    if (SubDDObj.ProfMTV) {
      webmd.ads2.adTarget = [ 'profpromo', 'medscptvdesktop' ];
    } else if (SubDDObj.ProfPromoNews) {
      webmd.ads2.adTarget = [ 'profpromo', 'medscpnewsdesktop' ];
    } else if (SubDDObj.ProfPromoLogin) {
      webmd.ads2.adTarget = [ 'profpromo', 'medscplogindesktop' ];
    } else if (SubDDObj.ProfPromoRef) {
      webmd.ads2.adTarget = [ 'profpromo', 'medscprefdesktop' ];
    } else if (SubDDObj.ProfMFR) {
      webmd.ads2.adTarget = [ 'profpromo', 'mfrnewsdesktop' ];
    } else if (SubDDObj.ProfMDENews) {
      webmd.ads2.adTarget = [ 'profpromo', 'mdenewsdesktop' ];
    } else if (SubDDObj.ProfMDEPraxis) {
      webmd.ads2.adTarget = [ 'profpromo', 'mdepraxisdesktop' ];
    } else if (SubDDObj.ProfEduES) {
      webmd.ads2.adTarget = [ 'profedu', 'mesedudesktop' ];
    } else if (SubDDObj.ProfUGC) {
      webmd.ads2.adTarget = [ 'profugc', 'messageboards' ];
    } else if (SubDDObj.ProfMES) {
      webmd.ads2.adTarget = [ 'profpromo', 'mesnewsdesktop' ];
    } else if (SubDDObj.ProfMPT) {
      webmd.ads2.adTarget = [ 'profpromo', 'mpornewsdesktop' ];
    } else if (SubDDObj.ProfEduFR) {
      webmd.ads2.adTarget = [ 'profedu', 'mfredudesktop' ];
    } else if (SubDDObj.ProfEduDE) {
      webmd.ads2.adTarget = [ 'profedu', 'mdeedudesktop' ];
    } else if (SubDDObj.ProfEduPT) {
      webmd.ads2.adTarget = [ 'profedu', 'mporedudesktop' ];
    } else if (SubDDObj.ProfEdu) {
      webmd.ads2.adTarget = [ 'profedu', 'profedudesktop' ];
    } else if (SubDDObj.ProfKaleoDOM) {
      webmd.ads2.adTarget = [ 'profpromo', 'medscpnewsdesktop/medscpkaleodesktop' ];
    } else if (SubDDObj.ProfODP) {
      webmd.ads2.adTarget = [ 'profpromo', 'medscpmdpdesktop' ];
    }
  } else if (DevTObj.isMobile) {
    if (SubDDObj.ProfMTV) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'medscptvmobileweb' ];
    } else if (SubDDObj.ProfPromoNews) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'medscpnewsmobileweb' ];
    } else if (SubDDObj.ProfPromoLogin) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'medscploginmobileweb' ];
    } else if (SubDDObj.ProfPromoRef) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'medscprefmobileweb' ];
    } else if (SubDDObj.ProfMFR) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'mfrnewsmobileweb' ];
    } else if (SubDDObj.ProfMDENews) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'mdenewsmobileweb' ];
    } else if (SubDDObj.ProfMDEPraxis) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'mdepraxismobileweb' ];
    } else if (SubDDObj.ProfUGC) {
      webmd.ads2.adTarget = [ 'profugc', 'messageboards' ];
    } else if (SubDDObj.ProfMES) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'mesnewsmobileweb' ];
    } else if (SubDDObj.ProfMPT) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'mpornewsmobileweb' ];
    } else if (SubDDObj.ProfEduFR) {
      webmd.ads2.adTarget = [ 'profedu', 'mfredumobileweb' ];
    } else if (SubDDObj.ProfEduDE) {
      webmd.ads2.adTarget = [ 'profedu', 'mdeedumobileweb' ];
    } else if (SubDDObj.ProfEduES) {
      webmd.ads2.adTarget = [ 'profedu', 'mesedumobileweb' ];
    } else if (SubDDObj.ProfEduPT) {
      webmd.ads2.adTarget = [ 'profedu', 'mporedumobileweb' ];
    } else if (SubDDObj.ProfEdu) {
      webmd.ads2.adTarget = [ 'profedu', 'profedumobileweb' ];
    } else if (SubDDObj.ProfKaleoDOM) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'medscpnewsmobileweb/medscpkaleomobileweb' ];
    } else if (SubDDObj.ProfODP) {
      webmd.ads2.adTarget = [ 'profpromomobileweb', 'medscpmdpmobileweb' ];
    }
  } else {
    webmd.ads2.adTarget = [ 'BLANK', 'BLANK' ];
  }
}
